














































import { mapGetters } from 'vuex';
import CommonIcon from 'common-modules/src/components/CommonIcon.vue';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Course } from 'common-modules/src/store/interface/Course';
import JwlButton from '@/components/JwlButton.vue';
import jwlCourse from '@/components/JwlCourse.vue';

const CommonError = () => import('common-modules/src/components/CommonError.vue');

@Component({
  components: {
    JwlButton,
    jwlCourse,
    CommonError,
    CommonIcon,
  },
  computed: mapGetters([
    'coursePreviews',
  ]),
})
export default class JwlCoursePreview extends Vue {
  coursePreviews!: Course[];

  loading = true;
  error = null;
  activeGroup = '';

  courseTypeClass (courseType: string): string {
    return `jwl-course-preview__course-wrapper--${courseType}`;
  }

  setInitialGroup (): void {
    if (this.groupedPreviews.teacherTraining.length > 0) {
      this.activeGroup = 'teacherTraining';
    } else if (this.groupedPreviews.academy.length > 0) {
      this.activeGroup = 'academy';
    }
  }

  get groupedPreviews (): Record<string, Course[]> {
    const teacherTraining = this.coursePreviews.filter((course) => course.type === 'teacher-training');
    const academy = this.coursePreviews.filter((course) => course.type === 'academy');

    return {
      teacherTraining,
      academy,
    };
  }

  get showFilterButtons (): boolean {
    let hasMultipleFilters = 0;
    if (this.groupedPreviews.academy.length > 0) {
      hasMultipleFilters += 1;
    }
    if (this.groupedPreviews.teacherTraining.length > 0) {
      hasMultipleFilters += 1;
    }

    return hasMultipleFilters >= 2;
  }

  mounted (): void {
    this.loading = true;
    this.$store.dispatch('getCoursePreviews')
      .then(() => {
        this.setInitialGroup();
        this.loading = false;
      })
      .catch((e) => {
        this.error = e;
      });
  }
}
